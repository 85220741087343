<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div
        class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
        id="page-login"
    >
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row no-gutter justify-center items-center">
                        <div class="vx-col hidden lg:block lg:w-1/2">
                            <img
                                src="@/assets/images/pages/login_gb_uno.png"
                                alt="login"
                                class="mx-auto"
                            />
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Bienvenido.</h4>
                                    <p>Ingresa tus datos.</p>
                                </div>
                                <vs-input
                                    v-validate="'required|email|min:3'"
                                    data-vv-validate-on="blur"
                                    name="email"
                                    id="emailUser"
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="Correo"
                                    v-model="form.email"
                                    class="w-full no-icon-border"
                                />
                                <span class="text-danger text-sm">{{
                                    errors.first("email")
                                }}</span>

                                <vs-input
                                    data-vv-validate-on="blur"
                                    v-validate="'required|min:6'"
                                    type="password"
                                    name="password"
                                    id="passwordUser"
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Contraseña"
                                    v-model="form.password"
                                    class="w-full mt-6 no-icon-border"
                                />
                                <span class="text-danger text-sm">{{
                                    errors.first("password")
                                }}</span>

                                <div
                                    class="flex flex-wrap justify-between my-5"
                                >
                                    <router-link to="/pages/change-password"
                                        >¿ Olvidaste tu contraseña
                                        ?</router-link
                                    >
                                </div>
                                <br />

                                <div v-if="view" class="form-group">
                                    <div class="col-xs-12" style="padding: 0px">
                                        <vue-recaptcha
                                            id="forTest"
                                            :sitekey="key"
                                            :loadRecaptchaScript="true"
                                            @verify="responseVerify"
                                        ></vue-recaptcha>
                                    </div>
                                </div>
                                <br />
                                <vs-alert
                                    id="alert-error"
                                    color="danger"
                                    active="true"
                                    v-if="errorMessage != ''"
                                    >{{ errorMessage }}</vs-alert
                                >
                                <div
                                    v-if="response && view"
                                    class="form-group form-actions"
                                >
                                    <div
                                        class="col-xs-12 text-center separator"
                                    >
                                        <vs-button
                                            id="btnLogin"
                                            class="float-right"
                                            :disabled="!validateForm"
                                            @click="login"
                                            >Ingresar</vs-button
                                        >
                                    </div>
                                </div>
                                <div
                                    v-if="!view"
                                    class="form-group form-actions"
                                >
                                    <div
                                        class="col-xs-12 text-center separator"
                                    >
                                        <vs-button
                                            id="btnLogin"
                                            class="float-right"
                                            :disabled="!validateForm"
                                            @click="login"
                                            >Ingresar</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        <user-status-modal ref="userStatusModal"></user-status-modal>
    </div>
</template>

<script>
import UserStatusModal from "./../../components/UserStatusModal";
import api from "../../mixins/api";
import VueRecaptcha from "vue-recaptcha";

export default {
    mixins: [api],
    data() {
        return {
            form: {
                email: "",
                password: ""
            },
            key: "",
            response: false,
            errorMessage: ""
        };
    },
    components: {
        UserStatusModal,
        VueRecaptcha
    },
    computed: {
        validateForm() {
            return this.email != "" && this.password != "";
        },
        countSessionInit: {
            get() {
                return this.$store.state.countSessionInit;
            },
            set(val) {
                this.$store.commit("changeCountSessionInit", val);
            }
        }
    },
    methods: {
        login() {
            var path = "/api/v1/login";
            this.requestPostNoAuth(this.url() + path, {
                user: { email: this.form.email, password: this.form.password }
            }).then(
                response => {
                    if (response.data.success == true) {
                        let data = response.data.data;

                        if (data.user.status === 2) {
                            this.form.password = "";
                            return this.$refs.userStatusModal.openModal();
                        }

                        let token = data.token;
                        let client = data.client;
                        let uid = data.uid;
                        delete data.token;
                        delete data.client;
                        delete data.uid;
                        const tokenEncrypted = this.CryptoJS.AES.encrypt(
                            token,
                            this.url()
                        ).toString();
                        const clientEncrypted = this.CryptoJS.AES.encrypt(
                            client,
                            this.url()
                        ).toString();
                        const uidEncrypted = this.CryptoJS.AES.encrypt(
                            uid,
                            this.url()
                        ).toString();
                        const passwordEncrypted = this.CryptoJS.AES.encrypt(
                            this.form.password,
                            this.url()
                        ).toString();
                        // window.localStorage.setItem('url_report', data.url_report)
                        window.localStorage.setItem("auth", true);
                        window.localStorage.setItem(
                            "contact_candidate",
                            data.contact_candidate
                        );
                        this.$store.commit("changeUserInfo", data);
                        this.$store.commit("changeVm0", tokenEncrypted);
                        this.$store.commit("changeVm1", clientEncrypted);
                        this.$store.commit("changeVm2", uidEncrypted);
                        this.$store.commit("changeVm3", passwordEncrypted);

                        this.$router.push({ name: "dashboardAnalytics" });
                    } else if (response.data.status == 304) {
                      this.showMessage(
                            response.data.message,
                            3000
                        );
                    }
                      else{
                        this.showMessage(
                            "Los datos ingresados no son correctos",
                            3000
                        );
                    }
                },
                () => {
                    this.showMessage(
                        "Los datos ingresados no son correctos",
                        3000
                    );
                }
            );
        },
        showMessage(message, time) {
            this.errorMessage = message;
            setTimeout(() => (this.errorMessage = ""), time);
        },
        loginAuth0() {
            if (this.$store.state.auth.isUserLoggedIn()) {
                this.notifyAlreadyLogedIn();
                return false;
            }
            this.$auth.login({ target: this.$route.query.to });
        },

        // Google login
        loginWithGoogle() {
            this.$store.dispatch("auth/loginWithGoogle", {
                notify: this.$vs.notify
            });
        },

        // Facebook login
        loginWithFacebook() {
            this.$store.dispatch("auth/loginWithFacebook", {
                notify: this.$vs.notify
            });
        },

        // Twitter login
        loginWithTwitter() {
            this.$store.dispatch("auth/loginWithTwitter", {
                notify: this.$vs.notify
            });
        },

        // Github login
        loginWithGithub() {
            this.$store.dispatch("auth/loginWithGithub", {
                notify: this.$vs.notify
            });
        },

        errorLogin(errorMessage) {
            this.$vs.notify({
                title: "Mensaje de Globalwork",
                text: errorMessage,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning"
            });
        },

        notifyAlreadyLogedIn() {
            this.$vs.notify({
                title: "Login Attempt",
                text: "You are already logged in!",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning"
            });
        },
        registerUser() {
            if (this.$store.state.auth.isUserLoggedIn()) {
                this.notifyAlreadyLogedIn();
                return false;
            }
            this.$router.push("/pages/register");
        },
        someMethod(event) {
            console.log(event);
            localStorage.setItem("a", "Se guardo");
            this.$router.push("/");
        },
        responseVerify(response) {
            let path = "api/v1/validate";
            let data = {
                secret: "6LcilhEqAAAAACRMzBNd3dRnDv1k_KmxTSAJGBph",
                response: response,
                keyword: "Globalwork2020$$"
            };
            this.requestPostNoAuth(this.url() + path, data).then(res => {
                if (res.data.response.success) {
                    this.response = true;
                }
            });
        }
    },
    created() {
        window.addEventListener("beforeunload", this.someMethod);
        this.key = "6LcilhEqAAAAAHE79QFDr71QEb7dXjwX0jvp7O8e";

        if (window.Cypress != undefined) {
            this.view = false;
        } else {
            this.view = true;
        }
    },
    watch: {
        countSessionInit() {
            if (this.countSessionInit == 2) {
                console.log("Van 2 intentos fallidos");
            }
        }
    }
};
</script>

<style lang="scss">
.full-page-bg-color {
    background-color: #ffffff !important;
}
#page-login {
    .social-login {
        .bg-facebook {
            background-color: #1551b1;
        }
        .bg-twitter {
            background-color: #00aaff;
        }
        .bg-google {
            background-color: #4285f4;
        }
        .bg-github {
            background-color: #333;
        }
    }
}
#btnLogin {
    margin-bottom: 1rem;
}
.icon-user,
.icon-lock {
    padding: 0 !important;
    margin-top: 0.2rem !important;
}

#alert-error {
    margin-bottom: 1rem !important;
}
</style>
