var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/login_gb_uno.png"),
                            alt: "login",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-full md:w-full lg:w-1/2" },
                      [
                        _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Bienvenido."),
                              ]),
                              _c("p", [_vm._v("Ingresa tus datos.")]),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email|min:3",
                                  expression: "'required|email|min:3'",
                                },
                              ],
                              staticClass: "w-full no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                name: "email",
                                id: "emailUser",
                                icon: "icon icon-user",
                                "icon-pack": "feather",
                                "label-placeholder": "Correo",
                              },
                              model: {
                                value: _vm.form.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "email", $$v)
                                },
                                expression: "form.email",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("email"))),
                            ]),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:6",
                                  expression: "'required|min:6'",
                                },
                              ],
                              staticClass: "w-full mt-6 no-icon-border",
                              attrs: {
                                "data-vv-validate-on": "blur",
                                type: "password",
                                name: "password",
                                id: "passwordUser",
                                icon: "icon icon-lock",
                                "icon-pack": "feather",
                                "label-placeholder": "Contraseña",
                              },
                              model: {
                                value: _vm.form.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "password", $$v)
                                },
                                expression: "form.password",
                              },
                            }),
                            _c("span", { staticClass: "text-danger text-sm" }, [
                              _vm._v(_vm._s(_vm.errors.first("password"))),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-between my-5",
                              },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/pages/change-password" } },
                                  [
                                    _vm._v(
                                      "¿ Olvidaste tu contraseña\n                                    ?"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("br"),
                            _vm.view
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-xs-12",
                                      staticStyle: { padding: "0px" },
                                    },
                                    [
                                      _c("vue-recaptcha", {
                                        attrs: {
                                          id: "forTest",
                                          sitekey: _vm.key,
                                          loadRecaptchaScript: true,
                                        },
                                        on: { verify: _vm.responseVerify },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _c("br"),
                            _vm.errorMessage != ""
                              ? _c(
                                  "vs-alert",
                                  {
                                    attrs: {
                                      id: "alert-error",
                                      color: "danger",
                                      active: "true",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.errorMessage))]
                                )
                              : _vm._e(),
                            _vm.response && _vm.view
                              ? _c(
                                  "div",
                                  { staticClass: "form-group form-actions" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 text-center separator",
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              id: "btnLogin",
                                              disabled: !_vm.validateForm,
                                            },
                                            on: { click: _vm.login },
                                          },
                                          [_vm._v("Ingresar")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.view
                              ? _c(
                                  "div",
                                  { staticClass: "form-group form-actions" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xs-12 text-center separator",
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              id: "btnLogin",
                                              disabled: !_vm.validateForm,
                                            },
                                            on: { click: _vm.login },
                                          },
                                          [_vm._v("Ingresar")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("user-status-modal", { ref: "userStatusModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }