<template>
    <div class="status">
        <template v-if="modalVisible">
            <div class="status__modal" @click="closeModal">
                <div class="status__container">
                    <img
                        src="./../assets/images/soporte_globalwork.png"
                        class="status__image"
                        width="140px"
                    />
                    <div class="status__title">
                        Acceso restringido a su cuenta
                    </div>
                    <p class="status__description">
                        En este momento su cuenta presenta una mora en cartera y
                        esto impide que pueda usar el aplicativo normalmente.
                    </p>
                    <p class="status__description">
                        Por favor comuníquese de manera urgente con el área de
                        cartera de Globalwork a estos numeros:
                        <span>(321) 469 8808</span>.
                    </p>
                    <a
                        href="mailto:contabilidad@globalwork.co"
                        class="status__calltoaction"
                        @click="acceptModal"
                    >
                        Contactar a Globalwork vía email
                    </a>
                    <div class="status__close" @click="closeModal">
                        Regresar al Inicio
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            modalVisible: false
        };
    },

    watch: {
        $route() {
            this.closeModal();
        }
    },

    methods: {
        openModal() {
            document.body.style.overflow = "hidden";
            this.modalVisible = true;
        },

        closeModal(event) {
            if (event && event.currentTarget !== event.target) {
                return;
            }

            this.resetModal();
        },

        acceptModal() {
            this.resetModal();
        },

        resetModal() {
            this.modalVisible = false;
            document.body.style.overflow = "auto";
        }
    },

    mounted() {
        window.addEventListener("keydown", e => {
            if (
                (this.modalVisible && e.code === "Escape") ||
                e.keyCode === 27
            ) {
                this.resetModal();
            }
        });
    }
};
</script>

<style lang="scss" scoped>
.status {
    $this: &;

    &__modal {
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        background: rgba(60, 26, 78, 0.7);
        color: #3c1a4e;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 525px;
        padding: 48px;
        border-radius: 10px;
        background: #ffffff;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    &__description {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        span {
            color: #5f0091;
            font-weight: bold;
        }
    }

    &__calltoaction,
    &__close {
        cursor: pointer;
    }

    &__calltoaction {
        width: 100%;
        background: linear-gradient(45deg, #5f0091 0%, #7f1cb2 100%);
        margin-top: 40px;
        padding: 16px;

        border-radius: 10px;
        border: 1px solid transparent;
        color: white;
        font-weight: bold;
    }

    &__close {
        margin-top: 24px;
        font-weight: bold;
    }
}
</style>
