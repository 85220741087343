var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status" },
    [
      _vm.modalVisible
        ? [
            _c(
              "div",
              { staticClass: "status__modal", on: { click: _vm.closeModal } },
              [
                _c("div", { staticClass: "status__container" }, [
                  _c("img", {
                    staticClass: "status__image",
                    attrs: {
                      src: require("./../assets/images/soporte_globalwork.png"),
                      width: "140px",
                    },
                  }),
                  _c("div", { staticClass: "status__title" }, [
                    _vm._v(
                      "\n                    Acceso restringido a su cuenta\n                "
                    ),
                  ]),
                  _c("p", { staticClass: "status__description" }, [
                    _vm._v(
                      "\n                    En este momento su cuenta presenta una mora en cartera y\n                    esto impide que pueda usar el aplicativo normalmente.\n                "
                    ),
                  ]),
                  _vm._m(0),
                  _c(
                    "a",
                    {
                      staticClass: "status__calltoaction",
                      attrs: { href: "mailto:contabilidad@globalwork.co" },
                      on: { click: _vm.acceptModal },
                    },
                    [
                      _vm._v(
                        "\n                    Contactar a Globalwork vía email\n                "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "status__close",
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n                    Regresar al Inicio\n                "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "status__description" }, [
      _vm._v(
        "\n                    Por favor comuníquese de manera urgente con el área de\n                    cartera de Globalwork a estos numeros:\n                    "
      ),
      _c("span", [_vm._v("(321) 469 8808")]),
      _vm._v(".\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }